<template>
  <div class="my-home">
    <h1>大嘴鸟AI管理后台</h1>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.my-home {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>